import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { PageContentBuilder } from "../components/partials/page-content-builder";

import Images from "../components/image-links/image-links"

const ImageGroupDiv = () => {
    return (
      <div>
        <section className="imageGroup">
          <div className="image-container">
            <div className="image-wrapper">
              <div className="image--style">
                <img
                  src={Images["image3"]}
                  alt="arrow"
                  className="img-group-style"
                  style={{ width: "100%" }}
                ></img>
              </div>
            </div>
          </div>
          <div className="image-container">
            <div className="image-wrapper">
              <div className="image--style">
                <img
                  src={Images["image2"]}
                  alt="arrow"
                  className="img-group-style"
                ></img>
              </div>
            </div>
          </div>
          <div className="image-container">
            <div className="image-wrapper">
              <div className="image--style">
                <img
                  src={Images["image6"]}
                  alt="arrow"
                  className="img-group-style"
                ></img>
              </div>
            </div>
          </div>
        </section>
        <section className="imageGroup2">
          <div className="image-container" style={{ gridColumn: "unset" }}>
            <div className="image-wrapper">
              <div className="image--style">
                <img
                  src={Images["image7"]}
                  alt="arrow"
                  className="img-group-style"
                ></img>
              </div>
            </div>
          </div>
          <div className="image-container">
            <div className="image-wrapper">
              <div className="image--style">
                <img
                  src={Images["image4"]}
                  alt="arrow"
                  className="img-group-style"
                ></img>
              </div>
              <br />
              <div className="image--style">
                <img
                  src={Images["image1"]}
                  alt="arrow"
                  className="img-group-style"
                ></img>
              </div>
            </div>
          </div>
          <div className="image-container">
            <div className="image-wrapper">
              <div className="image--style">
                <img
                  src={Images["image5"]}
                  alt="arrow"
                  className="img-group-style"
                ></img>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

const Page = ({ data, location}) => {
    const { contentNode = {} } = data;
    let  { values = "", meta = [], identifier = "" } = contentNode;
    values = JSON.parse(values);

    return <PageContentBuilder 
        headerActive={values.title} 
        location={location} 
        title={values.title}
        api={true} 
        PageContent={values} 
        subsection={identifier}
        meta={meta}
        useComponent={true}
        component={<ImageGroupDiv/>}
    />
}

const indexQuery = graphql`
  query {
    contentNode(identifier: {eq: "partial_campus"}) {
        identifier
        values
        meta {
            content
            name
        }
        
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <Page location={props.location} data={data} {...props} />
    )}
  />
)